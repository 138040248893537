import React from "react";
import Layout from "../components/layout";
import { StaticImage } from 'gatsby-plugin-image';
import Seo from '../components/seo';


const Masha = () => {
    return (
        <Layout>
            <Seo title="Masha Zhuk"/>
            <section className="member-container">
                <section className="member-intro">
                    <div className="member-intro_text">
                        <h1 className="member-intro_text_name">Masha Zhuk</h1>
                        <p className="member-intro_text_description">Dyplomowana instruktorka Pilatesu, wieloletnia pierwsza solistka Polskiego Baletu Narodowego
                        </p>
                    </div>
                    <div className="member-intro_picture-container">
                        <StaticImage 
                            src='../images/poza_masha.jpg' 
                            placeholder="blurred"
                            loading="eager"
                            alt="nstruktorka Masha Zhuk" 
                            className='member-intro_picture'
                        />
                    </div>
                </section>
                <section className="member-hero">
                    <section className="member-hero-paragraph">
                        <div className="member-hero-paragraph_title">
                            <h2 className="member-hero-paragraph_title-title">O MASHY</h2>
                        </div>
                        <div className="member-hero-paragraph_info">
                            <h3 className="member-hero-paragraph_info-bold">Dyplomowana instruktorka Pilatesu, absolwentka międzynarodowej Szkoły Pilatesu BASI Pilates, wieloletnia pierwsza solistka Polskiego Baletu Narodowego.
                            </h3>
                            <br/>
                            <p className="member-hero-paragraph_info-text">Masha pracuje metodą Pilates w nurcie współczesnym. Swoją drogę instruktorską rozpoczęła w szkole Open Mind, poznając metodykę pracy zarówno ze swoim ciałem jak i cudzym. Kursy nauczyły ją ćwiczeń na macie oraz uzupełniły te lekcje wiedzą teoretyczną z zakresu anatomii, biomechaniki i fizjologii człowieka. Swoją wiedzę czerpie z wielu kursów Pilatesowych.</p>
                            <p className="member-hero-paragraph_info-text">Jest absolwentką jednej z wiodących Szkół Pilatesu na Świecie - BASI Comprehensive Pilates Teacher. Podczas zajęć zaraża innych swoją pasją do metody Pilates i chce pomóc rozwinąć umiejętności optymalnym (żeby nie napisać perfekcyjnym) zarządzaniem swoim ciałem.</p>
                            <p className="member-hero-paragraph_info-text">Przez wiele lat była jedną z czołowych pierwszych solistek Polskiego Baletu Narodowego. Swoją karierę baletową zaczęła w Rosji, ale już w 2007 r. związała się z Teatrem Wielkim-Operą Narodową w Warszawie, gdzie występowała w całym klasycznym i współczesnym repertuarze. Tańczyła na wielu scenach świata. Dlatego już jako tancerka zauważyła pozytywny wpływ jaki ma metoda Pilates na pracę ciała.</p>
                        </div>
                    </section>
                    <section className="member-hero-paragraph">
                        <div className="member-hero-paragraph_title">
                            <h2 className="member-hero-paragraph_title-title">JĘZYKI<br/>LANGUAGES</h2>
                        </div>
                        <ul className="member-hero-paragraph_info-list">
                            <li>Polski/ Polish</li>
                            <li>Angielski/ English</li>
                            <li>Rosyjski/ Russian</li>
                        </ul>
                    </section>
                    <section className="member-hero-paragraph">
                        <div className="member-hero-paragraph_title">
                            <h2 className="member-hero-paragraph_title-title">METODY</h2>
                        </div>
                        <ul className="member-hero-paragraph_info-list">
                            <li>Pilates</li>
                            <li>Stretching</li>
                        </ul>
                    </section>
                    <section className="member-hero-paragraph">
                        <div className="member-hero-paragraph_title">
                            <h2 className="member-hero-paragraph_title-title">RODZAJE ZAJĘĆ</h2>
                        </div>
                        <ul className="member-hero-paragraph_info-list">
                                <li>Indywidualnie</li>
                                <li>W duetach</li>
                                <li>W minigrupach</li>
                            </ul>
                    </section>
                    <section className="member-hero-paragraph">
                        <div className="member-hero-paragraph_title">
                            <h2 className="member-hero-paragraph_title-title">RODZAJE SPRZĘTU</h2>
                        </div>
                        <ul className="member-hero-paragraph_info-list">
                                <li>Reformer with Tower</li>
                                <li>Cadillac</li>
                                <li>Ladder Barrel</li>
                                <li>Wunda Chair</li>
                                <li>Spine Corrector</li>
                                <li>Mata oraz sprzęt pilatesowy typu : Roller, Disc, Small Ball, Heavy Ball, Ovo Ball, Body Ball, Band, Magic Circle oraz dodatkowe akcesoria sportowe, np. małe ciężarki, piłeczki do automasażu, itp.</li>
                            </ul>
                    </section>
                    <section className="member-hero-paragraph">
                        <div className="member-hero-paragraph_title">
                            <h2 className="member-hero-paragraph_title-title">SPECJALIZACJE</h2>
                        </div>
                        <ul className="member-hero-paragraph_info-list">
                            <li>Tancerze (taniec współczesny i klasyczny, w tym balet)</li>
                            <li>Sportowcy</li>
                            <li>Osoby z wadami postawy</li>
                            <li>Kobiety po porodzie</li>
                        </ul>
                    </section>
                    <section className="member-hero-paragraph">
                        <div className="member-hero-paragraph_title"></div>
                        <h4 className="member-hero-paragraph_info-bold">Zapisy na zajęcia z Mashą tylko mailowo</h4>
                        <div className="member-hero-paragraph_info_link-container">
                            <a href="mailto:mashazhuk85@gmail.com" className="member-hero-paragraph_info_link-link">ZAPISZ SIĘ</a>
                        </div>
                    </section>
                </section>
            </section>
        </Layout>
    )
}

export default Masha;

